import React from 'react'
import PubSub from 'pubsub-js'
import { APP_PAGE_MOBILE_SLIDE_UPDATE } from '@/events'
import { Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import Form from '@/components/Form'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { pageFadeInOut } from '@/modules/animationVariants'
import { Left, MobileFooter } from '@/layouts/styled'
import { SideNavigationLink } from '@/components/SideNavigationLink'
import { useContentInProp } from '@/hooks/useContentInProp'
import MobileBack from '@/components/MobileBack'
import {
  MobileFormContainer,
  MobileFormContainerInner,
} from '@/components/Form/styled'

const HotelPage: React.FC<{ backUrl: string; forwardsUrl: string }> = ({
  backUrl,
  forwardsUrl,
}) => {
  const inProp = useContentInProp()
  const { t } = useTranslation()
  const isServer = typeof window === 'undefined'
  const mobileBreakpoint = 411

  const content = {
    pretitle: t(
      'home.followup_hotel_super',
      'fill copy key: home.followup_hotel_super',
    ),
    title: t(
      'home.followup_hotel_title',
      'fill copy key: home.followup_hotel_title',
    ),
    text: t(
      'home.followup_hotel_body',
      'fill copy key: home.followup_hotel_body',
    ),
  }

  const formDate = {
    title: t(
      'home.followup_hotel_form_date_title',
      'fill copy key: home.followup_hotel_form_date_title',
    ),
    button: t(
      'home.followup_hotel_form_date_btn',
      'fill copy key: home.followup_hotel_form_date_btn',
    ),
  }

  const formDetail = {
    title: t(
      'home.followup_hotel_form_detail_title',
      'fill copy key: home.followup_hotel_form_detail_title',
    ),
    placeholderName: t(
      'home.followup_hotel_form_detail_placeholder_name',
      'fill copy key: home.followup_hotel_form_detail_placeholder_name',
    ),
    placeholderEmail: t(
      'home.followup_hotel_form_detail_placeholder_email',
      'fill copy key: home.followup_hotel_form_detail_placeholder_email',
    ),
    placeholderPhone: t(
      'home.followup_hotel_form_detail_placeholder_phone',
      'fill copy key: home.followup_hotel_form_detail_placeholder_phone',
    ),
    button: t(
      'home.followup_hotel_form_detail_btn',
      'fill copy key: home.followup_hotel_form_detail_btn',
    ),
  }

  const formSend = {
    title: t(
      'home.followup_hotel_form_sent_title',
      'fill copy key: home.followup_hotel_form_sent_title',
    ),
    text: t(
      'home.followup_hotel_form_sent_body',
      'fill copy key: home.followup_hotel_form_sent_body',
    ),
    button: t(
      'home.followup_hotel_form_sent_btn',
      'fill copy key: home.followup_hotel_form_sent_btn',
    ),
  }

  const form = {
    dateData: formDate,
    detailData: formDetail,
    sentData: formSend,
  }

  const callToActionArray = ['Your Details', 'Send Request', 'Ok Great']

  const appointmentForm = (
    <Form
      withFooter
      callToAction={callToActionArray}
      variant="light"
      endpoint="https://busk2bjuke.execute-api.eu-west-1.amazonaws.com/dev/demo"
      {...form}
    />
  )
  const appointmentFormMobile = (
    <MobileFormContainer>
      <MobileFormContainerInner>
        <Form
          variant="light"
          callToAction={callToActionArray}
          endpoint="https://busk2bjuke.execute-api.eu-west-1.amazonaws.com/dev/demo"
          {...form}
          withFooter
          forwardsUrl={forwardsUrl}
          backUrl={backUrl}
        />
      </MobileFormContainerInner>
    </MobileFormContainer>
  )

  let mobileView = false
  if (!isServer) {
    mobileView = document.documentElement.clientWidth <= mobileBreakpoint
  }

  const showFormSlideOnMobile = () => {
    PubSub.publish(APP_PAGE_MOBILE_SLIDE_UPDATE, 1)
  }

  const mobileContent = {
    ...content,
    links: [
      {
        link: t(
          'home.followup_hotel_mobile_form_cta',
          'fill copy key: home.followup_hotel_mobile_form_cta',
        ),
        url: '',
        visibleOnDesktop: true,
        visibleOnXl: true,
        linkType: 'button',
        buttonCallback: showFormSlideOnMobile,
      },
    ],
  }

  return (
    <>
      <Left>
        {inProp && (
          <SideNavigationLink
            variant="light"
            position="left"
            delay={0}
            time={1}
            url={backUrl}
            text="back"
          />
        )}
      </Left>
      <Page
        content={
          <PageWrapper
            scrollable={!mobileView}
            useScaling={!mobileView}
            noPaddingMobile
            withStyleScrollingHighJack={false}
          >
            <WithSlideShowCheck
              autoPlay={false}
              fullWidth
              mobileContent={[
                <Text
                  variant="dark"
                  isMobile
                  padding="25px 25px 25px 25px"
                  {...mobileContent}
                />,
                appointmentFormMobile,
              ]}
            >
              <Box row>
                <Box col={0.4}>
                  <Text {...content} />
                </Box>
                <Box col={0.6} display="flex" justifyContent="flex-end">
                  {appointmentForm}
                </Box>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
      <MobileFooter id="mobile-footer">
        <MobileBack
          variant="light"
          delay={0}
          time={1}
          url="/home/followup"
          text="back"
        />
      </MobileFooter>
    </>
  )
}

export default HotelPage
